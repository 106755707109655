import { ROAD_QUOTATION_DANGEROUS_CLASSES, ROAD_QUOTATION_OPTIONS } from "../components/core/road-folder/types/constants";
import Devis from "./Devis";
import RoadDemandeFeatures from "./RoadDemandeFeatures";
import StopPoint from "./StopPoint";
import Account from "./account";
import Customer from "./customer";
import { StatusRoadDemande } from "./enums/status-road-demande.enum";
import Place from "./place";
import User from "./user";



export default class RoadDemande{
    id: number;
    client_id: number;
    contact_id: number;
    contact?: Account;
    status: StatusRoadDemande;
    code: string;

    origin_id: number;
    origin_date_start?: Date;
    origin_date_end?: Date;

    destination_id: number;
    destination_date_start?: Date;
    destination_date_end?: Date;

    created_by: number;
    main_assignment: number;
    secondary_assignment: number;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
    client?: Customer;
    origin?: Place;
    destination?: Place;
    author?: User | Account;
    main_assignee?: User;
    secondary_assignee?: User;
    features?: RoadDemandeFeatures;

    stop_points?: StopPoint[];
    last_devis?: Devis;

    constructor(data: any) {
        this.id = data.id;
        this.client_id = data.client_id;
        if (data.contact)
            this.contact = new Account(data.contact);
        this.contact_id = data.contact_id;
        this.status = data.status;
        this.code = data.code;
        this.origin_id = data.origin_id;
        if(data.origin_date_start)
            this.origin_date_start = new Date(data.origin_date_start);
        if(data.origin_date_end)
            this.origin_date_end = new Date(data.origin_date_end);

        this.destination_id = data.destination_id;
        if(data.destination_date_start)
            this.destination_date_start = new Date(data.destination_date_start);
        if(data.destination_date_end)
            this.destination_date_end = new Date(data.destination_date_end);

        this.created_by = data.created_by;
        this.main_assignment = data.main_assignment;
        this.secondary_assignment = data.secondary_assignment;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.deleted_at = data.deleted_at ? new Date(data.deleted_at) : undefined;
        if (data.client) {
            this.client = new Customer(data.client);
        }
        if (data.origin) {
            this.origin = new Place(data.origin);
        }
        if (data.destination) {
            this.destination = new Place(data.destination);
        }
        if (data.author && data.author.name) {
            this.author = new Account(data.author);
        } else if(data.author) {
            this.author = new User(data.author);
        }
        if (data.main_assignee) {
            this.main_assignee = new User(data.main_assignee);
        }
        if (data.secondary_assignee) {
            this.secondary_assignee = new User(data.secondary_assignee);
        }
        if (data.features) {
            this.features = new RoadDemandeFeatures(data.features);
        }

        if(data.stop_points){
            this.stop_points = data.stop_points.map((stop_point: any) => new StopPoint(stop_point));
        }

        if(data.last_devis){
            this.last_devis = new Devis(data.last_devis);
        }
    }

    getDefaultTabDemand() {
        if (this.status === StatusRoadDemande.NEW || this.status === StatusRoadDemande.DRAFT) {
            return 0;
        } else if (this.status === StatusRoadDemande.PENDING_PRICE) {
            return 1;
        }

        return 2;
    }

    getDefaultTabOnDetail() {
        if (this.status === StatusRoadDemande.NEW || this.status === StatusRoadDemande.DRAFT || this.status === StatusRoadDemande.PENDING_PRICE) {
            return 0;
        }

        return 2;
    }

    canValidate() {
        return this.status === StatusRoadDemande.NEW || this.status === StatusRoadDemande.DRAFT;
    }

    canEditPrice() {
        return this.status !== StatusRoadDemande.NEW && this.status !== StatusRoadDemande.DRAFT;
    }

    canShowDevis() {
        return this.status !== StatusRoadDemande.NEW && this.status !== StatusRoadDemande.DRAFT && this.status !== StatusRoadDemande.PENDING_PRICE;
    }

    getUnitLabel() {
        const label = ROAD_QUOTATION_OPTIONS.find((option: any) => option.value === this.features?.unit);

        return label ? label.label : '-';
    }

    getDangerousClassLabel() {
        const label = ROAD_QUOTATION_DANGEROUS_CLASSES.find((option: any) => option.value === this.features?.dangerous_substances_class);

        return label ? label.label : '-';
    }

    getLabelOrigin() {
        if (!this.origin) return '-';

        const postalCode = this.origin?.postal_code ? `${this.origin.postal_code}` : '';
        const locality = this.origin?.locality ? `${postalCode ? ', ' : ''}${this.origin.locality}` : '';
        const country = this.origin?.country ? `${locality ? ', ' : ''}${this.origin.country}` : '';

        return `${postalCode}${locality}${country}`;
    }

    getLabelDestination() {
        if (!this.destination) return '-';

        const postalCode = this.destination?.postal_code ? `${this.destination.postal_code}` : '';
        const locality = this.destination?.locality ? `${postalCode ? ', ' : ''}${this.destination.locality}` : '';
        const country = this.destination?.country ? `${locality ? ', ' : ''}${this.destination.country}` : '';

        return `${postalCode}${locality}${country}`;
    }
}