import type { ColumnsType } from "antd/es/table";
import { TaskTableType } from "../models/table/TaskTableType";

const columns: ColumnsType<TaskTableType> = [
    {
        title: "Tâches",
        dataIndex: "title",
        key: "title",
        sorter: false,
    },
    {
        title: "Attribué à",
        dataIndex: "user",
        key: "user",
        sorter: false,
    },
    {
        title: "Echéance",
        dataIndex: "due_date",
        key: "due_date",
        sorter: false,
    },
    {
        title: "Client",
        dataIndex: "client_name",
        key: "client_name",
        sorter: false,
    },
    {
        title: "Statuts",
        dataIndex: "status",
        key: "status",
        sorter: false,
    },
    {
        title: "Tâche attachée à",
        dataIndex: "reference",
        key: "reference",
        sorter: false,
    },
];

export { columns }