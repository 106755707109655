import { yupResolver } from "@hookform/resolvers/yup";
import RoadDemande from "../../../models/RoadDemande";
import { Button, Card, Col, Flex, Form, Row, Space } from "antd";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup"
import { Text } from "../../ui/typo/Text";
import { SaveOutlined, CheckOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import FormInput from "../../form/form-input";
import ReadonlyField from "../../form/readonly-field";
import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../../utils/hooks/useDebounce";
import roadDemandesService from "../../../services/road-demandes.service";
import FormCheckbox from "../../form/form-checkbox";
import FormDatePicker from "../../form/form-datepicker";
import FormTextarea from "../../form/form-textarea";
import InputFile from "../../ui/input-file/input-file";
import devisService from "../../../services/devis.service";
import Devis from "../../../models/Devis";
import dayjs from 'dayjs';
import dateHelper from "../../../utils/date.helper";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import axios from "axios";
import useDeepCompareEffect from "../../../utils/hooks/useDeepCompareEffect";
import EstimateDistanceCO2 from "../../../models/estimate-distance-co2";
import MapItinerary from "./map-itinerary";
import { Icon } from "../../ui/typo/Icon";

interface IProps{
    cotation: RoadDemande
    devis?: Devis
    canEdit: boolean
    refreshDevis: (goNext?: boolean) => void;
}

const attachmentType = 'internal';


type Inputs = {
    distance: number;
    price: number;
    front_option: {
        has_gazoil_surtax: boolean;
        has_custom_import: boolean;
        has_custom_export: boolean;
        has_tailgate: boolean;
        has_advalorem_insurance: boolean;
    },
    options: {
        customs_import?: number;
        customs_export?: number;
        tailgate?: number;
        advalorem_insurance?: number;
    }
    gazoil_surtax_rate?: number;
    fees: any[];
    display_gazoil_surtax: boolean;
    display_co2_rate: boolean;
    expiration_date: string;
    comments: string;
}
export default function FormQuotationPrice({ cotation, devis, canEdit, refreshDevis }: IProps) {

    const schema: any = yup.object().shape({
        distance: yup.number().required("La distance est requise !")
            .transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("La distance doit être un nombre"),
        price: yup.number().required("Le prix est requis !").transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("Le prix doit être un nombre"),
        front_option: yup.object().shape({
            has_gazoil_surtax: yup.boolean(),
            has_custom_import: yup.boolean(),
            has_custom_export: yup.boolean(),
            has_tailgate: yup.boolean(),
            has_advalorem_insurance: yup.boolean(),
        }),
        options: yup.object().shape({
            customs_import: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("Le montant doit être un nombre"),
            customs_export: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("Le montant doit être un nombre"),
            tailgate: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("Le montant doit être un nombre"),
            advalorem_insurance: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("Le montant doit être un nombre"),
        }),
        gazoil_surtax_rate: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("Le montant doit être un nombre"),
        fees: yup.array().of(
            yup.object().shape({
                name: yup.string().required("Le nom est requis !"),
                price: yup.number().required("Le prix est requis !").transform((value, originalValue) => {
                    return originalValue === '' ? NaN : value;
                }).typeError("Le montant doit être un nombre"),
            })
        ),
        display_gazoil_surtax: yup.boolean(),
        display_co2_rate: yup.boolean(),
        expiration_date: yup.string().required("La date d'expiration est requise !"),
        comments: yup.string(),
    });
    const [estimateDistanceCO2, setEstimateDistanceCO2] = useState<EstimateDistanceCO2>();
    const [estimateDistance, setEstimateDistance] = useState<EstimateDistanceCO2>();
    const [tmpInternalFiles, setTmpInternalFiles] = useState<any>([]);
    const [tmpDeletedInternalFiles, setTmpDeletedInternalFiles] = useState<any>([]);
    const [attachments, setAttachments] = useState<string[]>([]);
    const [totalEstimate, setTotalEstimate] = useState<number>()
    const [showMap, setShowMap] = useState(false);
    const [errorApiDistance, setErrorApiDistance] = useState(false);
    
    const {
        handleSubmit,
        control,
        register,
        formState: { isDirty, isValid, errors },
        reset,
        watch,
        getValues,
        setValue
    } = useForm<Inputs>({
        resolver: yupResolver(schema), mode: 'onChange',
        defaultValues: {
            fees: devis?.data?.fees ? devis?.data?.fees : []
        }
    })

    const { fields, append, remove } = useFieldArray({
        control: control as any,
        name: "fees", // Nom de votre champ dans le formulaire
     });

    const distance = watch('distance');
    const hasGazoilSurtax = watch('front_option.has_gazoil_surtax');
    const hasCustomImport = watch('front_option.has_custom_import');
    const hasCustomExport = watch('front_option.has_custom_export');
    const hasTailgate = watch('front_option.has_tailgate');
    const hasAdvaloremInsurance = watch('front_option.has_advalorem_insurance');

    const price = watch('price');
    const gazoil_surtax_rate = watch('gazoil_surtax_rate');
    const options = watch('options');
    const custom_import = watch('options.customs_import');
    const custom_export = watch('options.customs_export');
    const tailgate = watch('options.tailgate');
    const advalorem_insurance = watch('options.advalorem_insurance');
    const fees = watch('fees');
    const display_gazoil_surtax = watch('display_gazoil_surtax');

    useEffect(() => {
        if (!hasGazoilSurtax) {
            setValue('gazoil_surtax_rate', undefined);
        }
    }, [hasGazoilSurtax])

    useEffect(() => {
        if (!hasCustomImport) {
            setValue('options.customs_import', undefined);
        }
    }, [hasCustomImport])

    useEffect(() => {
        if (!hasCustomExport) {
            setValue('options.customs_export', undefined);
        }
    }, [hasCustomExport])

    useEffect(() => {
        if (!hasTailgate) {
            setValue('options.tailgate', undefined);
        }
    }, [hasTailgate])

    useEffect(() => {
        if (!hasAdvaloremInsurance) {
            setValue('options.advalorem_insurance', undefined);
        }
    }, [hasAdvaloremInsurance])


    const dispatch = useAppDispatch();

    const handleDistanceChange = useDebounce((newValue) => {
        async function getCO2() {
            if (cotation && newValue) {
                const res = await roadDemandesService.getEstimationCO2(cotation?.id, newValue);
                setEstimateDistanceCO2(res);
            }
        }
        getCO2();
    }, 500);

    useEffect(() => {
        async function getCO2() {
            if (cotation) {
                try {
                    const res = await roadDemandesService.getEstimationCO2(cotation.id);
           
                    setEstimateDistanceCO2(res);
                    setEstimateDistance(res);
                    if (res.distance && !devis?.data?.distance) {
                        console.log('set distance')
                        setValue('distance', res.distance);
                    } 
                } catch (e) {
                    setErrorApiDistance(true);
                    console.log(e);
                }
               
            }
        }
        getCO2();
    }, [cotation, devis])

    const rateLabel = useMemo(() => {
        if (estimateDistanceCO2 && estimateDistanceCO2.co2) {
            return `${estimateDistanceCO2.co2} kg CO2`;
        }
        return '-';
    }, [estimateDistanceCO2])

    useEffect(() => {
        handleDistanceChange(distance);
    }, [distance])

    useEffect(() => {
        if (cotation && devis) {
            fetchAttachments();
        }
    }, [cotation, devis])


    async function onSubmit(data: any) {
        try {
            const { front_option, ...rest } = data;
            await devisService.create(cotation?.id, rest);
            dispatch(addToast(new Toast("Le devis a été sauvegardé !", "success")));
            if (tmpInternalFiles && tmpInternalFiles.length > 0) {
                for (const file of tmpInternalFiles) {
                    await roadDemandesService.uploadAttachment(cotation.id, file, attachmentType);
                }
                setTmpInternalFiles([]);
            }

            if (tmpDeletedInternalFiles && tmpDeletedInternalFiles.length > 0) {
                for (const file of tmpDeletedInternalFiles) {
                    const filename = file.split("/").pop() || '';
                    await roadDemandesService.deleteAttachment(cotation.id, filename, attachmentType);
                }
                setTmpInternalFiles([]);
            }
            refreshDevis();
        } catch (e) {
            dispatch(addToast(new Toast("Le devis n'a pas été sauvegardé !", "error")));
            console.log(e);
        }
    }

    async function fetchAttachments() {
        if (cotation && devis) {
            const res = await roadDemandesService.getAttachments(cotation.id, attachmentType);
            setAttachments(res.map((item: any) => item.public_url));
        }
    }

    async function generateDevis() {
        try {
            const data = getValues();
            const { front_option, ...rest } = data;
            await devisService.create(cotation?.id, rest);
            await devisService.generate(cotation?.id);
            dispatch(addToast(new Toast("Le devis a été généré !", "success")));
            refreshDevis(true);
        } catch (e) {
            dispatch(addToast(new Toast("Le devis n'a pas été généré !", "error")));
            console.log(e);
        }
    }

    async function onDeleteAttachment(url: string) {
        setTmpDeletedInternalFiles([...tmpDeletedInternalFiles, url]);
        setAttachments(attachments.filter(a => a !== url));
    }

    async function onViewAttachment(url: string) {
        try {
            const response: any = await axios.get(url, {
                responseType: 'blob'
            });
            const blob = response.data;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const parts = url.split("/");
            const filename = parts[parts.length - 1];
            link.download = `${filename}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
            dispatch(addToast(new Toast(`Une erreur est survenue pendant le téléchargement du fichier !`, 'error')))
        }     
    }

    useDeepCompareEffect(() => {
        setEtimatePrice()
    }, [price, gazoil_surtax_rate, custom_import, custom_export, tailgate, advalorem_insurance, fees, display_gazoil_surtax])
    

    async function setEtimatePrice() {
        if (price || gazoil_surtax_rate || options || fees || display_gazoil_surtax) {
            const res = await roadDemandesService.getEstimateDevis(cotation?.id, {
                price,
                gazoil_surtax_rate,
                options,
                fees,
                display_gazoil_surtax
            });

            setTotalEstimate(res.total ? res.total : 0)
        }
    }

    return (
        <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} layout="vertical" requiredMark={false} onSubmitCapture={handleSubmit(onSubmit)}>
            <Flex justify="space-between" style={{ flexDirection: "column", height: '100%' }}>
                <div style={{ height: '100%', overflow: 'scroll' }}>
                    {devis && devis.isPending() &&
                        <Card style={{borderColor: '#990717', marginBottom: 10}}>
                            <Text target="Regular" style={{color:'#990717'}} >La cotation de transport est en cours de validation, vous ne pouvez plus la modifier !</Text>
                        </Card>
                    }

                    {devis && devis.isAccepted() &&
                        <Card style={{borderColor: '#079455', marginBottom: 10}}>
                            <Text target="Regular" style={{color:'#079455'}} >La cotation de transport a été validée, vous ne pouvez plus la modifier !</Text>
                        </Card>
                    }
                    
                    <Space direction="vertical" size={16} style={{ width: "100%" }}>
                        <Text target="SectionTitle">Distance kilométrique</Text>

                        <Row gutter={16} justify="space-between">
                            <Col span={10}>
                                <FormInput
                                    label="Distance en KM"
                                    required
                                    name="distance"
                                    defaultValue={devis?.data?.distance}
                                    placeholder="Entrer la distance"
                                    control={control}
                                    error={errors?.distance}
                                    disabled={!canEdit}
                                    prefixComponent={
                                        <>
                                            <Button style={showMap ? {borderColor: 'black'} : {}} onClick={() => setShowMap(!showMap)} icon={<Icon path="./icons/map.svg" size={20}  />}>
                                            </Button>  
                                        </>
                                    }
                                />

                                {errorApiDistance && <Text target="Regular" style={{color: 'red'}} >Ce trajet n'est pas accessible en camion.</Text>}
                            </Col>

                            <Col span={12}>
                                <ReadonlyField
                                    label="Taux de CO2"
                                    value={rateLabel}
                                    readonly
                                />
                            </Col>
                        </Row>
                        
                        {estimateDistance && estimateDistance.legs.length > 0 && estimateDistance.points.length > 0 && showMap ? <MapItinerary legs={estimateDistance.legs} points={estimateDistance.points} /> : <></>}

                        <Text target="SectionTitle">Choix du tarif du transport</Text>

                        <Col span={6}>
                            <FormInput
                                label="Tarif personnalisé"
                                required
                                name="price"
                                placeholder="Saisir tarif"
                                control={control}
                                error={errors?.price}
                                defaultValue={devis?.data?.price}
                                prefix="€ HT"
                                disabled={!canEdit}
                            />
                        </Col>

                        <Row gutter={16} justify="space-between" align="middle">
                            <Col span={12}>
                                <FormCheckbox
                                    label="Surtaxe gasoil"
                                    name="front_option.has_gazoil_surtax"
                                    control={control}
                                    defaultValue={devis?.data?.gazoil_surtax_rate ? true : false}
                                    disabled={!canEdit}
                                />
                            </Col>

                            {hasGazoilSurtax && <Col span={6}>
                                <FormInput
                                    label=""
                                    required
                                    name="gazoil_surtax_rate"
                                    placeholder="Saisir taux"
                                    control={control}
                                    error={errors?.gazoil_surtax_rate}
                                    prefix="%"
                                    labelIsVisible={false}
                                    defaultValue={devis?.data?.gazoil_surtax_rate}
                                    disabled={!canEdit}
                                />
                            </Col>}
                        </Row>
                        
                        <Text target="SectionTitle">Options</Text>

                        <Row gutter={16} justify="space-between" align="middle">
                            <Col span={12}>
                                <FormCheckbox
                                    label="Frais de douane - Import"
                                    name="front_option.has_custom_import"
                                    control={control}
                                    defaultValue={devis?.data?.options?.customs_import ? true : false}
                                    disabled={!canEdit}
                                />
                            </Col>

                            {hasCustomImport && <Col span={6}>
                                <FormInput
                                    label=""
                                    required
                                    name="options.customs_import"
                                    placeholder="Saisir montant"
                                    control={control}
                                    error={errors?.options?.customs_import}
                                    prefix="€ HT"
                                    labelIsVisible={false}
                                    defaultValue={devis?.data?.options?.customs_import}
                                    disabled={!canEdit}
                                />
                            </Col>}
                        </Row>

                        <Row gutter={16} justify="space-between" align="middle">
                            <Col span={12}>
                                <FormCheckbox
                                    label="Frais de douane - Export"
                                    name="front_option.has_custom_export"
                                    control={control}
                                    defaultValue={devis?.data?.options?.customs_export ? true : false}
                                    disabled={!canEdit}
                                />
                            </Col>

                            {hasCustomExport && <Col span={6}>
                                <FormInput
                                    label=""
                                    required
                                    name="options.customs_export"
                                    placeholder="Saisir montant"
                                    control={control}
                                    error={errors?.options?.customs_export}
                                    prefix="€ HT"
                                    labelIsVisible={false}
                                    defaultValue={devis?.data?.options?.customs_export}
                                    disabled={!canEdit}
                                />
                            </Col>}
                        </Row>

                        <Row gutter={16} justify="space-between" align="middle">
                            <Col span={12}>
                                <FormCheckbox
                                    label="Hayon"
                                    name="front_option.has_tailgate"
                                    control={control}
                                    defaultValue={devis?.data?.options?.tailgate ? true : false}
                                    disabled={!canEdit}
                                />
                            </Col>

                            {hasTailgate && <Col span={6}>
                                <FormInput
                                    label=""
                                    required
                                    name="options.tailgate"
                                    placeholder="Saisir montant"
                                    control={control}
                                    error={errors?.options?.tailgate}
                                    prefix="€ HT"
                                    labelIsVisible={false}
                                    defaultValue={devis?.data?.options?.tailgate}
                                    disabled={!canEdit}
                                />
                            </Col>}
                        </Row>

                        <Row gutter={16} justify="space-between" align="middle">
                            <Col span={19}>
                                <FormCheckbox
                                    label={`Assurance ad-valorem (Valeur de la marchandise ${cotation.features?.goods_value ? cotation.features?.goods_value : '-'} € HT)`}
                                    name="front_option.has_advalorem_insurance"
                                    control={control}
                                    defaultValue={devis?.data?.options?.advalorem_insurance ? true : false}
                                    disabled={!canEdit}
                                />
                            </Col>

                            {hasAdvaloremInsurance && <Col span={5}>
                                <FormInput
                                    label=""
                                    required
                                    name="options.advalorem_insurance"
                                    placeholder="Saisir taux"
                                    control={control}
                                    error={errors?.options?.tailgate}
                                    prefix="%"
                                    labelIsVisible={false}
                                    defaultValue={devis?.data?.options?.advalorem_insurance}
                                    disabled={!canEdit}
                                />
                            </Col>}
                        </Row>

                        <Text target="SectionTitle">Frais additionnels</Text>

                        <Space direction="vertical" size={16} style={{ width: "100%" }}>
                            {fields.map((field, index) => (
                                <div key={`field-${field.id}`} style={{width: "100%"}}>
                                    <Flex gap={16} style={{ width: "100%" }} >
                                       
                                        <Col span={14}>
                                            <FormInput
                                                label=""
                                                required
                                                name={`fees.${index}.name`}
                                                placeholder="Nom de l’option ajoutée"
                                                control={control}
                                                labelIsVisible={false}
                                                disabled={!canEdit}
                                            />
                                        </Col>

                                        <Col span={6}>
                                            <FormInput
                                                label=""
                                                required
                                                name={`fees.${index}.price`}
                                                placeholder="Saisir montant"
                                                control={control}
                                                prefix="€ HT"
                                                labelIsVisible={false}
                                                disabled={!canEdit}
                                            />
                                        </Col>

                                        
                                       
                                         <Col span={4}>
                                            <Button
                                                icon={<MinusOutlined />}
                                                onClick={() => remove(index)}
                                                disabled={!canEdit}
                                            />
                                        </Col>
                                        
                                    </Flex>
                                </div>
                            ))}

                            <Button
                                type="default"
                                icon={<PlusOutlined size={20} />}
                                onClick={() => append({
                                    name: "",
                                    price: "",
                                
                                })}
                                style={{ width: "100%" }}
                                disabled={!canEdit}
                            >
                                <Text target="Bold">Ajouter une option</Text>
                            </Button>
                        </Space>

                        <Text target="SectionTitle">Paramètres d’affichage</Text>

                        <Col span={12}>
                            <FormCheckbox
                                label="Afficher la surtaxe gasoil sur le devis"
                                name="display_gazoil_surtax"
                                control={control}
                                defaultValue={devis?.data?.display_gazoil_surtax}
                                disabled={!canEdit}
                            />
                        </Col>

                        <Col span={12}>
                            <FormCheckbox
                                label="Afficher le taux CO2 sur le devis"
                                name="display_co2_rate"
                                control={control}
                                defaultValue={devis?.data?.display_co2_rate === false ? false : true}
                                disabled={!canEdit}
                            />
                        </Col>

                        <Text target="SectionTitle">Durée de validité du devis</Text>

                        <Col span={12}>
                            <FormDatePicker
                                label="Date de fin de validité (3 mois par défaut)"
                                required
                                name="expiration_date"
                                control={control}
                                error={errors?.expiration_date}
                                placeholder="-- / -- / ----"
                                disabled={!canEdit}
                                defaultValue={devis?.expired_at ? dayjs(devis?.expired_at) :dayjs(dateHelper.getDateInThreeMonths()) }
                            />
                        </Col>

                        <Text target="SectionTitle">Commentaires internes DTS</Text>
                        <FormTextarea
                            label=""
                            name="comments"
                            placeholder="Ecrire un commentaire"
                            control={control}
                            error={errors?.comments}
                            labelIsVisible={false}
                            defaultValue={devis?.data.comments}
                            disabled={!canEdit}
                            className="interne"
                        />

                        <Text target="SectionTitle">Pièces jointes Internes DTS</Text>
                        <InputFile
                            onUpload={(file: any) => setTmpInternalFiles(file)}
                            existingUrls={attachments}
                            accept={['.pdf']}
                            onDelete={(url) => onDeleteAttachment(url)}
                            onView={(url) => onViewAttachment(url)}
                            disabled={!canEdit}
                            className="interne"
                        />

                       
                    </Space>
                </div>

                 <Space
                    direction="vertical"
                    size={16}
                    style={{ width: "100%" }}
                    align="end"
                >
                    <Text target="Bold">
                        Prix total : <span style={{ color: "#990717" }}>{totalEstimate || 0}€ HT</span>
                    </Text>
                    <Space
                        size={16}
                        style={{ justifyContent: "flex-end", width: "100%" }}
                    >
                        <Button type="primary" htmlType="submit" disabled={!isDirty || !isValid || !canEdit}>
                            <Space size={6}>
                                <SaveOutlined />
                                <Text target="Bold">Sauvegarder</Text>
                            </Space>
                        </Button>
                    
                        <Button type="primary" onClick={() => generateDevis()} disabled={!isDirty || !isValid || !canEdit}>
                            <Space size={6}>
                                <CheckOutlined />
                                <Text target="Bold">Générer le devis</Text>
                            </Space>
                        </Button>
                    </Space>
                </Space>
            </Flex>
        </Form>

    )
}