import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../../models/utils/data-paginate";
import useTable from "../../../utils/hooks/useTable";
import RoadDemande from "../../../models/RoadDemande";
import dateHelper from "../../../utils/date.helper";
import { Text } from "../../ui/typo/Text";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import roadContractsService from "../../../services/road-contracts.service";
import { columns } from "../../../configs/CustomerRoadContractsTableColumns";
import Contract from "../../../models/contract";

interface IProps{
    customerId: number;
}
export default function CustomerListContracts({ customerId }: IProps) {

    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState(1);
    const { renderColumns } = useTable(columns)
    const navigate = useNavigate();

    
    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const res = await roadContractsService.list(page, '', [{ name: 'client_id', value: customerId }]);
                setDatapaginate(res);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [page])

    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Contract) => {
            return {
                id: item.id,
                demande_id: item.demande_id,
                code: item.code,
                date:  dateHelper.dateToDDMMYYYY(item.loading_date_start),
                origin: item.getOriginLabel(),
                destination: item.getDestinationLabel(),
                price: item.selling_price ? `${item.selling_price} €` : '-',
            }
        });
    }, [dataPaginate]);

    return (
        <>
            <Text target="SectionTitle">Contrats de transport</Text>

            <Table
                style={{marginTop: 20}}
                rowKey={"id"}
                loading={loading}
                columns={renderColumns()}
                dataSource={dataTable}
                scroll={{ x: 1327, y: 1080 }}
                showSorterTooltip={false}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (record.id) {
                                navigate(`/road-contracts/${record.id}`)
                            }
                        }
                    }
                }}
                pagination={{
                pageSize: dataPaginate?.per_page || 10,
                total: dataPaginate?.total || 0,
                onChange: (page) => {
                    setPage(page);
                },
                }}
            />
        </>
    )

}